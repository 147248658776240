/**
 * Function for handling formatting price to have two number after comma
 * @param {string} price string of price that will be formatted and displayed
 * @returns formatted string of price
 */
const formatDisplayedPrice = (price) => {
  return `${parseFloat(price.toFixed(2)).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })} USD`;
};

export default formatDisplayedPrice;
