import React from 'react';

// utils
import formatDisplayedPrice from 'utils/format_displayed_price';

// variables
import listCompany from 'variables/whitelist_company';

const PriceContainer = (props) => {
  const {
    variableDiscount,
    variablePrice,
    className,
    courseOrganizer = '',
    totalCourse = {},
    purchasedCourse = [],
    isLarge,
    isMedium,
    isSmall,
    isNormal,
  } = props;

  const generatePrice = (priceArray) => {
    let price = 0;

    priceArray.every((item) => {
      if (item) {
        price = item;
        // return false means break from 'every()', means we found the price
        return false;
      }

      return true;
    });

    return parseFloat(price);
  };

  const isRealPriceShowed = (condition) => {
    return condition.some((item) => item);
  };

  // Below is condition when the price that displayed is the original price (not discount)

  // first condition: discount is 0
  const firstCondition = generatePrice(variableDiscount) === 0;

  // second condition: The checked course of the courseOrganizer is less than 2 courses, or no checked course at all (the component doesn't recieve checked course props)
  const secondCondition = totalCourse[courseOrganizer] ? totalCourse[courseOrganizer].length < 2 : true;

  // third condition: the courseOrganizer is in whitelist company and the second condition is true
  const thirdCondition =
    listCompany && listCompany?.findIndex((item) => item === courseOrganizer && secondCondition) > -1;

  // forth condition: The checked course of the courseOrganizer - the course of the courseOrganizer that purchased is more than 0 and less than 2.
  // if the component doesn't recieve checked course props, asume the value is -1 (will not fullfil the condition)
  const forthCondition = totalCourse[courseOrganizer]
    ? totalCourse[courseOrganizer].length - purchasedCourse.filter((item) => item.provider === courseOrganizer).length
    : -1;

  if (isRealPriceShowed([firstCondition, thirdCondition, forthCondition < 2 && forthCondition >= 0])) {
    return (
      <div className={`${className ? className : ''}`}>
        <div
          className={`price ${isLarge ? 'large' : ''} ${isMedium ? 'medium' : ''} ${isSmall ? 'small' : ''} ${
            isNormal ? 'normal' : ''
          }`}
        >
          {formatDisplayedPrice(generatePrice(variablePrice))}
        </div>
      </div>
    );
  }

  return (
    <div className={`${className ? className : ''}`}>
      <div
        className={`price ${isLarge ? 'large' : ''} ${isMedium ? 'medium' : ''} ${isSmall ? 'small' : ''} ${
          isNormal ? 'normal' : ''
        }`}
      >
        <font className="text-secondary me-2">
          <strike>{formatDisplayedPrice(generatePrice(variablePrice))}</strike>
        </font>
        {formatDisplayedPrice(generatePrice(variableDiscount))}
      </div>
    </div>
  );
};

export default PriceContainer;
