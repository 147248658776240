import { useCallback, useEffect, useState } from 'react';
// import { amsiFetch } from "utils/fetch";
import defaultImage from '../public/dummy-course-provider.png';

// const useFetch = url => {
//   const [loading, setLoading] = useState(false);
//   const [listData, setListData] = useState(null);

//   useEffect(() => {
//     let mounted = true;
//     const init = async () => {
//       setLoading(true)
//       try {
//         const response = await amsiFetch("GET", url, true);
//         if (mounted && response) setListData(response.data.data);
//       } catch(e) {
//         console.log(e)
//       }
//       setLoading(false)
//     }
//     init()
//     return () => mounted = false;
//   },[url]);

//   return {
//     loading,
//     listData
//   }
// }

const useImageHandler = ({ optionList, data }) => {
  const [currentImage, setCurrentImage] = useState(null);

  const handleImageToDefault = useCallback(() => {
    setCurrentImage(defaultImage.src);
  }, []);

  const handleIsImageAvailable = useCallback((property) => {
    if (property && property !== 'null' && property !== 'undefined' && property !== '') {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    let index = 0;
    for (let i = 0; i < optionList.length; i++) {
      if (handleIsImageAvailable(optionList[i])) {
        setCurrentImage(optionList[i]);
        break;
      } else {
        index++;
      }
    }

    if (index === optionList.length) handleImageToDefault();
  }, [optionList]);

  return {
    currentImage,
    handleIsImageAvailable,
    handleImageToDefault,
  };
};

export {
  // useFetch,
  useImageHandler,
};
