import React from 'react';

const BundleTypeBadge = ({bundleType, extraClass=''}) => {
  let text = ''
  let classN = `badgeBase ${extraClass} `;
  switch (bundleType) {
    case 0:
      text = 'Course Bundle'
      classN += 'pink'
      break
    case 1:
      text = 'Product Bundle'
      classN += 'lightBlue'
      break
    case 2:
      text = 'Course & Product Bundle'
      classN += 'darkBlue'
      break
  }

  return (
    <div height="20px" className={classN}>{text}</div>
  )
}

export default BundleTypeBadge;
