import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Router from 'next/router';

const ModalWrapper = ({ setOpen, open, children, isFullHeight, isBottom }) => {

  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  },[])

  const handleClose = (e) => {
    e.preventDefault();
    setOpen(false);
    Router.reload(window.location.pathname);
  }

  const modalContent = open ? (
    <div>
      <div onClick={handleClose} className="modal-description-checkout"/>
      <div
        className={`modal-description-container ${isFullHeight ? "is-full-height" : ""} ${isBottom ? "is-bottom" : ""}`}
        style={{
          position:"fixed",
          zIndex: 1000,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}> 
        {children}
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root")
    )
  } else {
    return null;
  }
}

export default ModalWrapper;